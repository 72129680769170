// survey store module
// ---
//
// ------------------------------------------------------------------------- /
import apiService from '@/services/api';
import { logAndToastError, resetState } from '@/utils';

// Initial state
// -------------------------------------------------------------------------
const initialState = {
	survey: undefined,
};

// Getters
// ------------------------------------------------------------------------- /
const getters = {
	survey: state => state.survey,
};

// Actions
// ------------------------------------------------------------------------- /
const actions = {
	getSurveyByBrandId({ commit, state }, { businessRelationId, brandId }) {
		if (!state.survey?.length) {
			const url = `/businessrelations/${businessRelationId}/survey?brandId=${brandId}`;
			apiService
				.get(url)
				.then(({ data: survey }) => {
					commit('setSurvey', survey[0]);
				})
				.catch(err => {
					logAndToastError(commit, 'Failed to get all survey from the server', err, { url });
				});
		}
	},
	resetSurvey({ commit }) {
		commit('resetSurvey');
	},
};

// Mutations
// ------------------------------------------------------------------------- /
const mutations = {
	setSurvey(state, survey) {
		state.survey = survey;
	},
	resetSurvey(state) {
		resetState(state, initialState);
	},
};

export default {
	state: { ...initialState },
	getters,
	actions,
	mutations,
};
