// Batteries store module
// ---
//
// ------------------------------------------------------------------------- /
import get from 'lodash/get';

import apiService from '@/services/api';
import { ITEMS_PER_PAGE } from '@/services/constants';
import { MessageTypes } from '@/enums/message-types';
import { logAndToastError } from '@/utils';
import RecursiveError from '@/services/recursiveError';
import logger from '../../services/logger';
import { resetState } from '../../utils/reset-state';

export const BatteryStatus = {
	AtStockLocation: 'At stock location',
	Created: 'Created',
	Missing: 'Missing',
	PickUpPlanned: 'Pick up planned',
	Processed: 'Processed',
	ReadyForPickUp: 'Ready for pick-up',
};

// Initial state
// ------------------------------------------------------------------------- /
const initialState = {
	checkedBatteries: null,
	checkedBatteriesCount: 0,
	chemicalFamilies: null,
	batteryConditions: null,
	batteryStatuses: null,
};

// Getters
// ------------------------------------------------------------------------- /
const getters = {
	checkedBatteries: state => state.checkedBatteries,
	checkedBatteriesCount: state => state.checkedBatteriesCount,
	chemicalFamilies: state => state.chemicalFamilies,
	batteryConditions: state => state.batteryConditions,
	batteryStatuses: state => state.batteryStatuses,
	getBatteryConditionNameById: state => batteryId => {
		const condition = state.batteryConditions?.find(condition => condition.id === batteryId);

		return condition ? condition.name : 'N/A'; // Default to 'Unknown' if not found
	},
};

/**
 * Returns a lookup table with the key being the name of the status and the value being the id of the status
 * @return {Promise<{[key: string]: string}>}
 */
async function getBatteryStatusesFromApi() {
	const url = '/batteries/statuses';
	try {
		const response = await apiService.get(url);

		const batteryStatuses = get(response, 'data');
		if (!batteryStatuses || !batteryStatuses.length) {
			throw new RecursiveError('No battery statuses were returned from the api', null, {
				url,
				responseData: batteryStatuses,
				statusText: response.statusText,
				status: response.status,
				type: MessageTypes.ERROR,
			});
		}

		return Object.fromEntries(batteryStatuses.map(batteryStatus => [batteryStatus.name, batteryStatus.id]));
	} catch (err) {
		throw new RecursiveError('Failed to get battery statuses from the server', err, { url });
	}
}

// Actions
// ------------------------------------------------------------------------- /
const actions = {
	getCheckedBatteries: async ({ state, commit }, { businessRelationId, page, pageSize }) => {
		let url;
		let body;
		try {
			url = `/businessrelations/${businessRelationId}/batteries/search?page=${page || 0}&pageSize=${pageSize || ITEMS_PER_PAGE.DOUBLE}`;
			let { batteryStatuses } = state;
			if (!batteryStatuses) {
				batteryStatuses = await getBatteryStatusesFromApi();
				commit('getBatteryStatuses', { batteryStatuses });
			}
			if (!batteryStatuses[BatteryStatus.AtStockLocation]) {
				logAndToastError(commit, 'Failed to get checked batteries from the server, failed to get battery status id', null, {
					url,
					batteryStatuses,
					statusName: BatteryStatus.AtStockLocation,
				});

				return;
			}
			body = {
				isResponsibleCp: true,
				batteryStatusIds: [batteryStatuses[BatteryStatus.AtStockLocation]],
			};

			const response = await apiService.post(url, body);

			const checkedBatteries = get(response, 'data.items', []);
			const checkedBatteriesCount = get(response, 'data.totalItems', 0);

			commit('setCheckedBatteries', {
				checkedBatteries,
				checkedBatteriesCount,
			});
		} catch (err) {
			logAndToastError(commit, 'Failed to get checked batteries from the server', err, {
				url,
				body,
			});
		}
	},
	getChemicalFamilies({ commit, state }) {
		if (!state.chemicalFamilies) {
			const url = '/batteries/chemicalfamilies';
			apiService
				.get(url)
				.then(response => {
					const chemicalFamilies = get(response, 'data');
					if (!chemicalFamilies || !chemicalFamilies.length) {
						const errorMessage = 'No chemicalFamilies of batteries were returned from the api';
						logger.error(errorMessage, null, {
							url,
							response,
						});
						commit('setMessage', {
							text: errorMessage,
							type: MessageTypes.ERROR,
						});
					}
					commit('setChemicalFamilies', { chemicalFamilies });
				})
				.catch(err => {
					logAndToastError(commit, 'Failed to get chemical families from the server', err, { url });
				});
		}
	},
	getBatteryConditions({ commit, state }) {
		if (!state.batteryConditions) {
			const url = '/batteries/conditions';
			apiService
				.get(url)
				.then(response => {
					const batteryConditions = get(response, 'data');
					if (!batteryConditions || !batteryConditions.length) {
						const errorMessage = 'No battery conditions were returned from the api';
						logger.error(errorMessage, null, {
							url,
							response,
						});
						commit('setMessage', {
							text: errorMessage,
							type: MessageTypes.ERROR,
						});
					}
					commit('setBatteryConditions', { batteryConditions });
				})
				.catch(err => {
					logAndToastError(commit, 'Failed to get battery conditions from the server', err, { url });
				});
		}
	},
	getBatteryStatuses: async ({ commit, state }) => {
		if (!state.batteryStatuses) {
			try {
				const batteryStatuses = await getBatteryStatusesFromApi();
				commit('getBatteryStatuses', { batteryStatuses });
			} catch (err) {
				logAndToastError(commit, 'Failed to get battery statuses from the server', err);
			}
		}
	},
	resetBatteries({ commit }) {
		commit('resetBatteries');
	},
};

// Mutations
// ------------------------------------------------------------------------- /
const mutations = {
	setCheckedBatteries(state, { checkedBatteries, checkedBatteriesCount }) {
		state.checkedBatteries = checkedBatteries;
		state.checkedBatteriesCount = checkedBatteriesCount;
	},
	setChemicalFamilies(state, { chemicalFamilies }) {
		state.chemicalFamilies = chemicalFamilies;
	},
	setBatteryConditions(state, { batteryConditions }) {
		state.batteryConditions = batteryConditions;
	},
	getBatteryStatuses(state, { batteryStatuses }) {
		state.batteryStatuses = batteryStatuses;
	},
	setUpdatedBattery(state, newBattery) {
		const battery = state.items.find(item => item.id === newBattery.id);
		Object.assign(battery, {
			...newBattery,
		});
	},
	resetBatteries(state) {
		resetState(state, initialState);
	},
};

export default {
	state: { ...initialState },
	getters,
	actions,
	mutations,
};
