const ButtonTypes: { [key: string]: string } = Object.freeze({
	Button: 'button',
	Reset: 'reset',
	Submit: 'submit',
});

const ButtonClasses: { [className: string]: string } = Object.freeze({
	Base: 'a-button',
	// Icon
	Icon: 'a-button--icon',
	// Colors
	Blue: 'a-button--blue',
	Green: 'a-button--green',
	Red: 'a-button--red',
	Grey: 'a-button--grey',
	White: 'a-button--white',
	// Appearance
	Outline: 'a-button--outline',
	OutlineWhite: 'a-button--outline-white',
	Round: 'a-button--round',
	Transparent: 'a-button--transparent',
	Shrink: 'a-button--shrink',
	Nowrap: 'a-button--nowrap',
	// Sizes
	XSmall: 'a-button--xs',
	Small: 'a-button--sm',
	XLarge: 'a-button--xl',
	FullWidth: 'a-button--full-width',
	// States
	Disabled: 'a-button--disabled',
});

export { ButtonTypes, ButtonClasses };
