// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

import Vue from 'vue';

// App setup
// ------------------------------------------------------------------------- /
import clickOutside from '@/directives/click-outside';
import * as svgicon from 'vue-svgicon';
import VTooltip from 'v-tooltip';
import { App } from './components';
import router from './router';
import store from './store';
import i18n from './i18n';
import { ReneosWindow } from '@/window';
// import * as Sentry from '@sentry/vue';
// import { BrowserTracing } from '@sentry/tracing';
import vuetify from './plugins/vuetify';

// surveyjs
import 'survey-core/defaultV2.fontless.min.css';
import 'survey-core/i18n/french';
import 'survey-core/i18n/german';
import 'survey-core/i18n/italian';
import 'survey-core/i18n/greek';
import 'survey-core/i18n/polish';
import 'survey-core/i18n/dutch';
import 'survey-core/i18n/english';
import 'survey-core/i18n/spanish';
import 'survey-core/i18n/czech';
import 'survey-core/i18n/romanian';
import 'survey-core/i18n/portuguese';

interface AppInterface {
	el: string;
	store: any;
	router: any;
	vuetify?: any;
	components: {
		App: any;
	};
	template: string;
	i18n: any;
}

const app: AppInterface = {
	el: '#app',
	store,
	router,
	vuetify,
	components: {
		App,
	},
	template: '<App/>',
	i18n,
};

Vue.config.productionTip = false;
Vue.directive('click-outside', clickOutside);

// Translations
// ------------------------------------------------------------------------- /
Object.defineProperty(Vue.prototype, '$locale', {
	get: () => app.i18n.locale,
	set: locale => {
		app.i18n.locale = locale;
	},
});

Vue.use(svgicon, {
	tagName: 'svg-icon',
	classPrefix: 'q',
});

// Store the version on the window object, so we know the build made it to the server after a deploy
(window as ReneosWindow).version = require('../package.json').version;

Vue.use(VTooltip);

// Sentry.init({
// 	Vue,
// 	dsn: 'https://cf2ff777b7534bdf8ecf58e1511046ba@o552626.ingest.sentry.io/6313489',
// 	integrations: [
// 		new BrowserTracing({
// 			routingInstrumentation: Sentry.vueRouterInstrumentation(router),
// 			tracingOrigins: ['https://app.reneos.eu/', 'https://api-reneos.eucosys.eu/', /^\//],
// 		}),
// 	],
// 	tracesSampleRate: 1.0,
// });

// Export
// ------------------------------------------------------------------------- /
export default new Vue(app);
