import { mapGetters } from 'vuex';
import get from 'lodash/get';

import { RnHeader, RnForm, RnButton, RnNotification, RnIcon } from '@/components';
import { addPickupPointFormMixin, addressInformationFormMixin, contactPersonFormMixin } from '@/mixins';
import { mapNewPupData } from '@/services/businessRelations';
import apiService from '@/services/api';
import logger from '@/services/logger';
import { MessageTypes } from '@/enums/message-types';

export default {
	name: 'rn-new-pickup-point-view',
	components: {
		RnHeader,
		RnForm,
		RnButton,
		RnNotification,
		RnIcon,
	},

	data() {
		return {
			isLoading: false,
		};
	},

	created() {
		const brandIds = get(this.user, 'businessRelation.brands', []).map(({ id }) => id);

		this.$store.dispatch('getFilteredCountries', { brandIds: brandIds });
		this.$store.dispatch('getCountriesRequirements', { brandIds: brandIds });
	},

	mixins: [addPickupPointFormMixin, addressInformationFormMixin, contactPersonFormMixin],

	computed: {
		...mapGetters(['user', 'userName', 'location', 'addresses']),
	},

	methods: {
		async submitForm() {
			if (
				this.validateBusinessRelationFormModel(this.businessRelationFormModel) &&
				this.validateAddressInformationFormModel(this.addressInformationFormModel, false) &&
				this.validateContactPersonFormModel(this.contactPersonFormModel)
			) {
				this.isLoading = true;

				const data = {
					businessRelation: this.businessRelationFormModel,
					address: this.addressInformationFormModel,
					contactPerson: this.contactPersonFormModel,
				};

				const formData = mapNewPupData(data);

				try {
					let res = await apiService.post(`/businessrelations/${this.user.businessRelation.id}/createPup`, formData);

					res = get(res, 'data', null);
					if (res.success) {
						this.$store.dispatch('setMessage', {
							text: 'message.createPup.success',
							type: MessageTypes.SUCCESS,
						});
						this.$router.push({ name: 'MFPickupPoints' });
					}
				} catch (error) {
					logger.error('Failed to create PUP', error);
					this.$store.dispatch('setMessage', {
						text: get(error, 'response.data.errors[0].message'),
						type: MessageTypes.ERROR,
					});
				} finally {
					this.isLoading = false;
				}
			}
		},

		goBack() {
			this.$router.push({ name: 'MFPickupPoints' });
		},
	},
};
