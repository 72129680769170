// BatteryDescriptions store module
// ---
//
// ------------------------------------------------------------------------- /
import Vue from 'vue';
import apiService from '@/services/api';
import { logAndToastError, resetState } from '../../utils';

// Initial state
// ------------------------------------------------------------------------- /
const initialState = {
	batteryModels: [],
};

// Getters
// ------------------------------------------------------------------------- /
const getters = {
	batteryModels: state => state.batteryModels,
	getModelNameById: state => modelId => {
		const models = state.batteryModels || [];
		const model = models.find(item => item.batteryModelId === modelId);
		return model?.name ?? 'N/A';
	},
	getModelChemicalFamilyById: state => modelId => {
		const models = state.batteryModels || [];
		const model = models.find(item => item.batteryModelId === modelId);
		return model?.chemicalFamily.name ?? 'N/A';
	},
};

// Actions
// ------------------------------------------------------------------------- /
const actions = {
	async getBatteryModels({ commit }, { businessRelationId, brandId } = {}) {
		const url = `/businessrelations/${businessRelationId}/batterymodels${brandId ? `?brandId=${brandId}` : ''}&pageSize=1000`;

		try {
			const { data: batteryModels } = await apiService.get(url);

			commit('setBatteryModels', batteryModels.items);

			return batteryModels.items;
		} catch (err) {
			logAndToastError(commit, 'Failed to get battery models from the server', err, {
				url,
			});
		}
	},
	resetBatteryDescriptions({ commit }) {
		commit('resetBatteryDescriptions');
	},
};

// Mutations
// ------------------------------------------------------------------------- /
const mutations = {
	setBatteryModels(state, batteryModels) {
		Vue.set(state, 'batteryModels', batteryModels);
	},
	resetBatteryDescriptions(state) {
		resetState(state, initialState);
	},
};

export default {
	state: { ...initialState },
	getters,
	actions,
	mutations,
};
