import { mapGetters } from 'vuex';
import { RnHeader, RnButton, RnOrder, RnGetStarted, RnSpinner, RnPagination, RnAlert } from '@/components';
import { ITEMS_PER_PAGE } from '@/services/constants';

export default {
	name: 'rn-pup-dashboard-view',
	components: {
		RnHeader,
		RnButton,
		RnOrder,
		RnGetStarted,
		RnSpinner,
		RnPagination,
		RnAlert,
	},
	data() {
		return {
			ITEMS_PER_PAGE: ITEMS_PER_PAGE.DEFAULT,
			page: 0,
			getStartedItems: [
				{
					icon: require('@/assets/img/request-collection.svg'),
					title: 'pup.overview.descriptions.requestCollection.title',
					content: 'pup.overview.descriptions.requestCollection.body',
				},
				{
					icon: require('@/assets/img/request-status.svg'),
					title: 'pup.overview.descriptions.requestStatus.title',
					content: 'pup.overview.descriptions.requestStatus.body',
				},
				{
					icon: require('@/assets/img/request-overview.svg'),
					title: 'pup.overview.descriptions.requestOverview.title',
					content: 'pup.overview.descriptions.requestOverview.body',
				},
			],
		};
	},
	computed: {
		...mapGetters(['hasRequiredCompanyNumber', 'pupCountry', 'userName', 'pupCollectionOrders', 'pupCollectionOrdersCount', 'businessRelationId']),
		orderedCollectionOrders() {
			return (this.pupCollectionOrders || []).sort((a, b) => new Date(b.orderCreationDate) - new Date(a.orderCreationDate));
		},
		collectionOrdersState() {
			if (!this.pupCollectionOrders) {
				return 'loading';
			}
			if (this.pupCollectionOrders.length) {
				return 'results';
			}
			return 'noResults';
		},
	},
	created() {
		this.updateCollectionOrdersPaginated(0);
	},
	methods: {
		requestCollection() {
			this.$router.push({ name: 'PUPNewRequest' });
		},
		updateCollectionOrdersPaginated(page) {
			this.page = page;
			this.$store.dispatch('getPupCollectionOrders', {
				businessRelationId: this.businessRelationId,
				page,
				pageSize: this.ITEMS_PER_PAGE,
			});
		},
	},
};
