import get from 'lodash/get';

import { MessageTypes } from '@/enums/message-types';
import apiService from '@/services/api';
import logger from '@/services/logger';

export const fetchLanguages = async () => {
	try {
		const res = await apiService.get('/crm/languages');

		return get(res, 'data', null);
	} catch (error) {
		logger.error('Failed to fetch languages', error);
		this.$store.dispatch('setMessage', { text: get(error, 'response.data.errors[0].message'), type: MessageTypes.ERROR });
	}
};

export const fetchCountries = async () => {
	try {
		const res = await apiService.get('/crm/countries');

		return get(res, 'data', null);
	} catch (error) {
		logger.error('Failed to fetch countries', error);
		this.$store.dispatch('setMessage', { text: get(error, 'response.data.errors[0].message'), type: MessageTypes.ERROR });
	}
};

export const fetchFilteredCountries = async brandIds => {
	try {
		const results = await Promise.all(brandIds.map(brandId => apiService.post(`/crm/countries/search?brandId=${brandId}`)));

		// merge the countries for different brandIds while avoiding duplicates
		const countries = results.reduce((acc, curr) => {
			get(curr, 'data', []).forEach(item => {
				if (!acc.some(({ id }) => id === item.id)) {
					acc.push(item);
				}
			});
			return acc;
		}, []);

		return countries;
	} catch (error) {
		logger.error('Failed to fetch countries', error);
		this.$store.dispatch('setMessage', { text: get(error, 'response.data.errors[0].message'), type: MessageTypes.ERROR });
	}
};

export const fetchCountriesRequirements = async brandIds => {
	try {
		const results = await Promise.all(brandIds.map(brandId => apiService.get(`/manufacturer/brandId?brandId=${brandId}`)));

		// merge the countries for different brandIds while avoiding duplicates
		const requirements = results.reduce((acc, curr) => {
			get(curr, 'data', []).forEach(item => {
				const index = acc.findIndex(({ country }) => country === item.country);

				if (index <= -1) {
					acc.push(item);
					// Pick the strictest rules
				} else if (index > -1 && !acc[index].companyNumberRequired && item.companyNumberRequired) {
					acc[index] = item;
				}
			});

			return acc;
		}, []);

		return requirements;
	} catch (error) {
		logger.error('Failed to fetch requirements by countries', error);
		this.$store.dispatch('setMessage', { text: get(error, 'response.data.errors[0].message'), type: MessageTypes.ERROR });
	}
};
