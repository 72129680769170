import { mapGetters } from 'vuex';
import { Roles } from '@/enums/roles';
import { get } from 'lodash';

const addressModel = {
	streetName: '',
	streetNumber: '',
	bus: '',
	zipCode: '',
	city: '',
	country: '',
};

const addressInformationFormMixin = {
	data() {
		return {
			pupCountry: undefined,
			addressInformationFormModel: [
				{
					companyName: '',
				},
				{
					...addressModel,
				},
			],
			addressInformationFormFieldErrors: [
				{
					companyName: '',
				},
				{
					...addressModel,
				},
			],
			addressInformationFormTemplate: {
				...addressModel,
			},
		};
	},
	created() {
		if (this.$store.getters.defaultRole.name === Roles.PUP) {
			const brandIds = get(this.user, 'collectionOrganisations', [])?.map(({ brandId }) => brandId);

			if (!!brandIds?.length) {
				this.$store.dispatch('getFilteredCountries', { brandIds: brandIds });
				this.$store.dispatch('getCountriesRequirements', { brandIds: brandIds });
			}
		}
	},
	computed: {
		...mapGetters(['user', 'isAddressInformationChangesPending', 'countries', 'filteredCountries']),

		addressInformationFieldGroups() {
			return () => [
				{
					fields: [
						{
							cols: '6',
							inputProps: {
								id: 'address-info-street-name',
								placeholder: this.$t('user.addressInfo.streetName.placeholder'),
								type: 'text',
							},
							label: this.$t('user.addressInfo.streetName'),
							modelKey: 'streetName',
							required: true,
						},
						{
							cols: '3',
							inputProps: {
								id: 'address-info-street-number',
								placeholder: this.$t('user.addressInfo.streetNumber.placeholder'),
								type: 'text',
							},
							label: this.$t('user.addressInfo.streetNumber'),
							modelKey: 'streetNumber',
							required: true,
						},
						{
							cols: '3',
							inputProps: {
								id: 'address-info-bus',
								placeholder: this.$t('user.addressInfo.bus.placeholder'),
								type: 'text',
							},
							label: this.$t('user.addressInfo.bus'),
							modelKey: 'bus',
						},
						{
							cols: '6',
							inputProps: {
								id: 'address-info-zip-code',
								placeholder: this.$t('user.addressInfo.zipCode.placeholder'),
								type: 'text',
							},
							label: this.$t('user.addressInfo.zipCode'),
							modelKey: 'zipCode',
							required: true,
						},
						{
							cols: '6',
							inputProps: {
								id: 'address-info-city',
								placeholder: this.$t('user.addressInfo.city.placeholder'),
								type: 'text',
							},
							modelKey: 'city',
							label: this.$t('user.addressInfo.city'),
							required: true,
						},
						{
							cols: '6',
							inputProps: {
								id: 'address-info-country',
								placeholder: this.$t('user.addressInfo.country.placeholder'),
								options: this.filteredCountryOptions.length > 0 ? this.filteredCountryOptions : this.countryOptions,
								type: 'select',
							},
							label: this.$t('user.addressInfo.country'),
							modelKey: 'country',
							required: true,
							onValueChanged: value => (this.pupCountry = value),
						},
					],
				},
			];
		},
		addressInformationCompanyFieldGroups() {
			return [
				{
					fields: [
						{
							cols: '6',
							push: '6',
							inputProps: {
								id: 'address-info-company-name',
								placeholder: this.$t('user.addressInfo.companyName.placeholder'),
								type: 'text',
							},
							label: this.$t('user.addressInfo.companyName'),
							modelKey: 'companyName',
							required: true,
						},
					],
				},
			];
		},
		filteredCountryOptions() {
			return this.filteredCountries.map(country => ({
				name: country.name,
				value: country.name,
			}));
		},
		countryOptions() {
			return this.countries.map(country => ({
				name: country.name,
				value: country.name,
			}));
		},
		addressInfoForm() {
			return this.$store.state.forms.addressInformation;
		},
		addressInformationForm() {
			return {
				model: this.addressInformationFormModel,
				companyModel: this.addressInformationFormCompanyModel,
				fieldGroups: this.addressInformationFieldGroups(),
				newPickUpPointFieldGroups: this.addressInformationFieldGroups(),
				companyFieldGroups: this.addressInformationCompanyFieldGroups,
				modelUpdateHandler: this.updateAddressInformationFormModel,
				addFacility: this.addFacility,
				actionHandler: this.addressInformationFormAction,
				form: this.addressInfoForm,
				fieldErrors: this.addressInformationFormFieldErrors,
			};
		},
	},
	methods: {
		updateAddressInformationFormModel(newModel) {
			this.clearAddressInformationFormError();
			const { id } = newModel;
			delete newModel.id;
			this.addressInformationFormModel[id] = newModel;
		},
		addFacility() {
			this.addressInformationFormModel.push(this.addressInformationFormTemplate);
			this.addressInformationFormFieldErrors.push(this.addressInformationFormTemplate);
		},
		removeFacility(i) {
			return () => {
				this.addressInformationFormModel.splice(i, 1);
				this.addressInformationFormFieldErrors.splice(i, 1);
			};
		},
		addressInformationFormAction() {
			if (this.validateAddressInformationFormModel(this.addressInformationFormModel)) {
				this.$store.dispatch('updateAddresses', this.addressInformationFormModel);
			}
		},
		clearAddressInformationFormFieldErrors(i) {
			if (i === 0) {
				this.addressInformationFormFieldErrors[i] = {
					companyName: '',
				};
			} else {
				this.addressInformationFormFieldErrors[i] = {
					streetName: '',
					streetNumber: '',
					bus: '',
					zipCode: '',
					city: '',
					country: '',
				};
			}
		},
		clearAddressInformationFormError() {
			this.$store.dispatch('clearFormErrors', { form: 'addressInformation' });
		},
		validateAddressInformationFormModel(models, withCompany = true) {
			this.clearAddressInformationFormError();

			let valid = true;

			models.forEach((model, i) => {
				this.clearAddressInformationFormFieldErrors(i);

				if (i === 0) {
					if (withCompany && !model.companyName) {
						this.addressInformationFormFieldErrors[i].companyName = this.$t('error.required', [this.$t('user.addressInfo.companyName')]);
						valid = false;
					}
				} else {
					if (!model.streetName) {
						this.addressInformationFormFieldErrors[i].streetName = this.$t('error.required', [this.$t('user.addressInfo.streetName')]);
						valid = false;
					}

					if (!model.streetNumber) {
						this.addressInformationFormFieldErrors[i].streetNumber = this.$t('error.required', [this.$t('user.addressInfo.streetNumber')]);
						valid = false;
					}

					if (!model.zipCode) {
						this.addressInformationFormFieldErrors[i].zipCode = this.$t('error.required', [this.$t('user.addressInfo.zipCode')]);
						valid = false;
					}

					if (!model.city) {
						this.addressInformationFormFieldErrors[i].city = this.$t('error.required', [this.$t('user.addressInfo.city')]);
						valid = false;
					}

					if (!model.country) {
						this.addressInformationFormFieldErrors[i].country = this.$t('error.required', [this.$t('user.addressInfo.country')]);
						valid = false;
					}
				}
			});

			return valid;
		},
	},
	watch: {
		location: {
			immediate: true,
			handler() {
				this.addressInformationFormModel[0].companyName = this.location.name;
			},
		},
		addresses: {
			immediate: true,
			handler() {
				this.pupCountry = this.addresses[0]?.country;

				for (let i = 0; i < this.addresses.length; i++) {
					this.addressInformationFormModel[i + 1] = this.addressInformationFormModel[i + 1] || {};
					this.addressInformationFormModel[i + 1].streetName = this.addresses[i].street;
					this.addressInformationFormModel[i + 1].streetNumber = this.addresses[i].number;
					this.addressInformationFormModel[i + 1].bus = this.addresses[i].postOfficeBox;
					this.addressInformationFormModel[i + 1].zipCode = this.addresses[i].zipCode;
					this.addressInformationFormModel[i + 1].city = this.addresses[i].city;
					this.addressInformationFormModel[i + 1].country = this.addresses[i].country;
				}
				this.$store.dispatch('updateAddressInformationChangesPending');
			},
		},
	},
};

export default addressInformationFormMixin;
