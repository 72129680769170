export const ALLOWED_MIME_TYPES_PER_UPLOAD_TYPE = Object.freeze({
	photo: {
		mimeTypes: ['image/jpeg', 'image/png', 'image/gif'],
		allowedExtensions: 'jpg, png, gif',
	},
	statusReport: {
		mimeTypes: ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
		allowedExtensions: 'pdf, doc, docx',
	},
});

export const ALLOWED_MIME_TYPES = Object.freeze({
	mimeTypes: [...ALLOWED_MIME_TYPES_PER_UPLOAD_TYPE.photo.mimeTypes, ...ALLOWED_MIME_TYPES_PER_UPLOAD_TYPE.statusReport.mimeTypes],
	allowedExtensions: `${ALLOWED_MIME_TYPES_PER_UPLOAD_TYPE.photo.allowedExtensions}, ${ALLOWED_MIME_TYPES_PER_UPLOAD_TYPE.statusReport.allowedExtensions}`,
});
