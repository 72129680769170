// Brands store module
// ---
//
// ------------------------------------------------------------------------- /
import apiService from '@/services/api';
import { logAndToastError, resetState } from '@/utils';
import { get } from 'lodash';

// Helpers
// ------------------------------------------------------------------------- /
const parseBrands = brands =>
	brands.map(brand => ({
		...brand,
		value: brand.id,
	}));

// Initial state
// ------------------------------------------------------------------------- /
const initialState = {
	items: [],
	companyNumberRequirements: [],
};

// Getters
// ------------------------------------------------------------------------- /
const getters = {
	brands: state => state.items || [],
	brandCompanyNumberRequired: state => businessRelationId => {
		const brands = get(state, `items`, undefined);
		const brand = brands.find(item => item.manufacturer?.id === businessRelationId);
		const companyNumberRequired = brand?.companyNumberRequired;
		return companyNumberRequired ?? undefined;
	},
	brandCompanyNumberFormat: state => businessRelationId => {
		const brands = get(state, `items`, undefined);
		const brand = brands.find(item => item.manufacturer?.id === businessRelationId);
		const companyNumberFormat = brand?.companyNumberFormat;
		return companyNumberFormat ?? undefined;
	},
	getBrandNameById: state => brandId => {
		const brands = get(state, `items`, undefined);
		const brand = brands.find(item => item.id === brandId);
		return brand?.name ?? undefined;
	},
	getCompanyNumberRequirementsByCountry: state => country => {
		return state.companyNumberRequirements.find(requirement => requirement.country === country);
	},
};

// Actions
// ------------------------------------------------------------------------- /
const actions = {
	getAllBrands({ commit, state }, businessRelationId) {
		if (!state.items.length) {
			const url = `/manufacturer/brands?businessRelationId=${businessRelationId}`;
			apiService
				.get(url)
				.then(({ data: brands }) => {
					commit('setBrands', parseBrands(brands));
				})
				.catch(err => {
					logAndToastError(commit, 'Failed to get all brands from the server', err, { url });
				});
		}
	},
	resetBrands({ commit }) {
		commit('resetBrands');
	},
};

// Mutations
// ------------------------------------------------------------------------- /
const mutations = {
	setBrands(state, brands) {
		state.items = brands;
	},
	setCompanyNumberRequirementsPerCountry(state, companyNumberRequirements) {
		state.companyNumberRequirements = companyNumberRequirements;
	},
	resetBrands(state) {
		resetState(state, initialState);
	},
};

export default {
	state: { ...initialState },
	getters,
	actions,
	mutations,
};
