// User store module
// ---
//
// ------------------------------------------------------------------------- /
import Vue from 'vue';
import apiService from '@/services/api';
import { MessageTypes } from '@/enums/message-types';
import { get, debounce, map, compact, isNil, pick, keys, unset } from 'lodash';
import hash from 'object-hash';
import logger from '@/services/logger';
import { resetState } from '@/utils';
import serverService from '@/services/server';
import { ITEMS_PER_PAGE } from '@/services/constants';

const CONTACT_INFO_STORAGE_KEY = 'reneos.contactInfo.hash';
const ADDRESS_INFO_STORAGE_KEY = 'reneos.addressInfo.hash';

// Helpers
const defaultNotificationFetchOptions = {
	skipReadNotifications: true,
	page: 0,
	pageSize: ITEMS_PER_PAGE.DEFAULT,
};

function getContactInfoFromState(state) {
	return {
		firstName: state.user.firstName,
		lastName: state.user.lastName,
		email: state.user.email,
		phone: state.user.phone,
		language: state.user.language.substring(0, 2),
	};
}

function getAddressInfoFromState(state) {
	return state.user.locations;
}

const getNotificationsDebounced = debounce(
	(userId, commit, skipReadNotifications = true, page, pageSize) => {
		if (!skipReadNotifications) {
			const readUrl = `/users/${userId}/notifications?page=${page || 0}&pageSize=${pageSize || ITEMS_PER_PAGE.DOUBLE}&isSeen=true&important=false`;
			apiService
				.get(readUrl)
				.then(response => {
					commit('setReadNotifications', get(response, 'data.items', []));
					commit('setReadNotificationsCount', get(response, 'data.totalItems', 0));
				})
				.catch(err => {
					// Don't log this to the user in a toast since it could get annoying on every page
					logger.error('Failed to get read notifications from the server', err, { url: readUrl });
				});
		}

		const unreadUrl = `/users/${userId}/notifications?page=${page || 0}&pageSize=${pageSize || ITEMS_PER_PAGE.DOUBLE}&isSeen=false&important=false`;
		apiService
			.get(unreadUrl)
			.then(response => {
				commit('setUnreadNotifications', get(response, 'data.items', []));
				commit('setUnreadNotificationsCount', get(response, 'data.totalItems', 0));
			})
			.catch(err => {
				// Don't log this to the user in a toast since it could get annoying on every page
				logger.error('Failed to get unread notifications from the server', err, { url: unreadUrl });
			});

		const importantUrl = `/users/${userId}/notifications?page=0&pageSize=${ITEMS_PER_PAGE.DOUBLE}&isSeen=false&important=true`;
		apiService
			.get(importantUrl)
			.then(response => {
				commit('setImportantNotifications', get(response, 'data.items', []));
			})
			.catch(err => {
				// Don't log this to the user in a toast since it could get annoying on every page
				logger.error('Failed to get important notifications from the server', err, { url: unreadUrl });
			});
	},
	200,
	{
		leading: false,
		trailing: true,
	}
);

function getTaskInfo(user, oldInfo, newInfo, title, description, link) {
	const structuredData = {
		operation: 'UPDATE',
		objectType: 'address',
		service: 'reneos',
		personId: user.personId,
		old: oldInfo,
		new: pick(newInfo, keys(oldInfo)),
	};
	const linkHtml = link ? `You can update this info at: <a href="${link}">${link}</a><br/>` : '';
	const changedFields = compact(
		map(oldInfo, (value, key) => {
			if (value !== newInfo[key] && (!isNil(value) || !isNil(newInfo[key]))) {
				return key;
			}
			return null;
		})
	);
	const taskInfo = {
		title,
		description: `${description}
<br/>
These fields have been changed:
<br/>
<dl>
  ${changedFields.map(key => `<dt>${key}</dt><dd>${newInfo[key]}</dd>`).join('')}
</dl>
<br/>
${linkHtml}
<div id="structured_data" style="display: none">${JSON.stringify(structuredData)}</div>`,
	};

	return taskInfo;
}

// Initial state
// ------------------------------------------------------------------------- /
const initialState = {
	user: null,
	isContactInformationChangesPending: false,
	isAddressInformationChangesPending: false,
	envs: {},
	loading: false,
};

// Getters
// ------------------------------------------------------------------------- /
const getters = {
	user: state => state.user,
	userName: state => `${get(state, 'user.firstName')} ${get(state, 'user.lastName')}`,
	coId: state => get(state, 'user.collectionOrganisations[0].id'),
	coName: state => get(state, 'user.collectionOrganisations[0].name'),
	pupCountry: state => get(state, 'user.locations[0].addresses[0].country').replace(/\s+/g, '-'),
	userId: state => get(state, 'user.personId'),
	businessRelationId: state => get(state, 'user.businessRelationId'),
	location: state => get(state, 'user.locations[0]', {}),
	locations: state => get(state, 'user.locations', []),
	locationId: state => get(state, 'user.locations[0].id'),
	hasRequiredCompanyNumber: state => {
		const required = get(state, 'user.locations[0].companyNumberRequired');

		return !required || (required && get(state, 'user.locations[0].companyNumber'));
	},
	addresses: state => get(state, 'user.locations[0].addresses') || get(state, 'user.businessRelation.addresses'),
	roles: state => get(state, 'user.businessRelation.roles', []),
	defaultRole: state => get(state, 'user.businessRelation.roles[0]', {}),
	isContactInformationChangesPending: state => get(state, 'isContactInformationChangesPending', initialState.isContactInformationChangesPending),
	isAddressInformationChangesPending: state => get(state, 'isAddressInformationChangesPending', initialState.isAddressInformationChangesPending),
	readNotifications: state => get(state, 'notifications.read', []),
	unreadNotifications: state => get(state, 'notifications.unread', []),
	importantNotifications: state => get(state, 'notifications.important', []),
	readNotificationsCount: state => get(state, 'notifications.readCount', 0),
	unreadNotificationsCount: state => get(state, 'notifications.unreadCount', 0),
	loading: state => state.loading,
	envs: state => state.envs,
};

// Actions
// ------------------------------------------------------------------------- /
const actions = {
	/**
	 * Checks if the user is still logged in on the server using the session cookie
	 * If they are, fetch the user info from the server session
	 * @param dispatch
	 */
	async checkLogin({ dispatch }) {
		try {
			const response = await apiService.get('/users');

			if (!response || !response?.data) {
				return null;
			}

			const { data } = response;

			const user = {
				...data.userInfo,
				personId: data.personId,
				businessRelation: data.businessRelation,
				locations: data.locations || [],
			};

			dispatch('setUser', user);
		} catch (err) {
			console.error('Fetching the logged in user from the server session failed', err);
		}
	},
	async revalidateLocations({ dispatch }) {
		try {
			const response = await apiService.get('/users//revalidate-location');

			if (!response || !response?.data) {
				return null;
			}

			const { data } = response;

			const user = {
				...data.userInfo,
				personId: data.personId,
				businessRelation: data.businessRelation,
				locations: data.locations || [],
			};

			dispatch('setUser', user);
		} catch (err) {
			console.error('Fetching the logged in user from the server session failed', err);
		}
	},
	login({ dispatch }, credentials) {
		apiService
			.post('/users/validate', credentials)
			.then(({ data: response }) => {
				if (response.success) {
					if (!get(response, 'businessRelation.roles', []).length) {
						dispatch('setFormErrors', {
							form: 'login',
							errors: ['user.noRoles'],
						});

						return;
					}
					dispatch('setUser', {
						...response.userInfo,
						personId: response.personId,
						businessRelation: response.businessRelation,
						locations: response.locations,
					});
				} else if (response.errors) {
					dispatch('setFormErrors', {
						form: 'login',
						errors: get(response, 'errors', ['default']),
					});
				}
				dispatch('getAllNotifications');
			})
			.catch(error => {
				let errors = get(error, 'response.data.errors', ['default']);
				if (error.message === 'Network Error') {
					errors = ['connectionError'];
				}
				dispatch('setFormErrors', {
					form: 'login',
					errors,
				});
			});
	},
	setUser({ commit }, user) {
		commit('setUser', user);
	},
	logout({ dispatch }) {
		// Remove the user from the current session with the backend
		apiService.post('/users/logout');

		// Clean all stores
		dispatch('resetUser');
		dispatch('resetOpsInvoices');
		dispatch('resetSpocInvoices');
		dispatch('resetMfInvoices');
		dispatch('resetPupInvoices');
		dispatch('resetBatteries');
		dispatch('resetBatteryDescriptions');
		dispatch('resetBrands');
		dispatch('resetCollectionOrders');
		dispatch('resetContracts');
		dispatch('resetForms');
		dispatch('resetGlobal');
		dispatch('resetLocations');
		dispatch('clearMessage');
		dispatch('resetNavigation');
		dispatch('resetPackaging');
		dispatch('resetResources');
	},
	updateAddresses({ dispatch, commit, state }, newInfo) {
		const id = state.user.businessRelationId;

		const oldInfo = {
			streetName: get(state.user, 'locations[0].addresses[0].street'),
			streetNumber: get(state.user, 'locations[0].addresses[0].number'),
			bus: get(state.user, 'locations[0].addresses[0].postOfficeBox'),
			zipCode: get(state.user, 'locations[0].addresses[0].zipCode'),
			city: get(state.user, 'locations[0].addresses[0].city'),
			country: get(state.user, 'locations[0].addresses[0].country'),
		};

		commit('setLoading', true);

		apiService
			.post(
				`/businessrelations/${id}/tasks`,
				getTaskInfo(
					state.user,
					oldInfo,
					newInfo[1],
					'Reneos: Request to change address information',
					`The address info has been updated for ${state.user.firstName} ${state.user.lastName}`,
					`${state.envs.EDIT_PERSON_ENDPOINT}/${state.user.personId}`
				)
			)
			.then(({ data: response }) => {
				commit('setLoading', false);

				if (response.success) {
					dispatch('setMessage', {
						text: 'message.address.success',
						type: MessageTypes.SUCCESS,
					});

					// Store old address detail, so we can track when the info from the server has changed, then we know the reneos employee as updated the address info
					localStorage.setItem(ADDRESS_INFO_STORAGE_KEY, hash(getAddressInfoFromState(state)));
				} else {
					dispatch('setInfoFormErrors', {
						form: 'addressInformation',
						errors: get(response, 'modelState.id'),
					});
				}
			})
			.catch(error => {
				commit('setLoading', false);

				dispatch('setInfoFormErrors', {
					form: 'addressInformation',
					errors: error.response,
				});
			});
	},
	updateContactInformation({ dispatch, commit, state }, newInfo) {
		const id = state.user.businessRelationId;
		const oldInfo = {
			firstName: state.user.firstName,
			lastName: state.user.lastName,
			email: state.user.email,
			phone: state.user.phone,
			language: state.user.language.substring(0, 2),
		};

		commit('setLoading', true);

		apiService
			.post(
				`/businessrelations/${id}/tasks`,
				getTaskInfo(
					state.user,
					oldInfo,
					newInfo,
					'Reneos: Request to change contact information',
					`The contact info has been updated for ${state.user.firstName} ${state.user.lastName}`,
					`${state.envs.EDIT_PERSON_ENDPOINT}/${state.user.personId}`
				)
			)
			.then(({ data: response }) => {
				commit('setLoading', false);

				if (response.success) {
					dispatch('setMessage', {
						text: 'message.contact.success',
						type: MessageTypes.SUCCESS,
					});

					// Store old contact detail, so we can track when the info from the server has changed, then we know the reneos employee as updated the contact info
					localStorage.setItem(CONTACT_INFO_STORAGE_KEY, hash(getContactInfoFromState(state)));
					dispatch('updateContactInformationChangesPending');
				} else {
					dispatch('setMessage', {
						text: 'message.contact.error',
						type: MessageTypes.ERROR,
					});
				}
			})
			.catch(error => {
				commit('setLoading', false);

				dispatch('setInfoFormErrors', {
					form: 'contactInformation',
					errors: error.response,
				});
			});
	},
	changePassword({ dispatch, state }, credentials) {
		apiService
			.put(`/users/${state.user.personId}/password`, credentials)
			.then(({ data: response }) => {
				if (response.success) {
					dispatch('setMessage', {
						text: 'message.password.success',
						type: MessageTypes.SUCCESS,
					});
				}
			})
			.catch(error => {
				dispatch('setFormErrors', {
					form: 'password',
					errors: get(error, 'response.data.errors', ['default']),
				});
			});
	},
	getAllNotifications({ commit, getters }, { skipReadNotifications, page, pageSize } = defaultNotificationFetchOptions) {
		const { userId } = getters;

		if (userId) {
			getNotificationsDebounced(userId, commit, skipReadNotifications, page, pageSize);
		}
	},
	updateContactInformationChangesPending({ commit, state }) {
		let oldUserContactInfoHash;
		try {
			oldUserContactInfoHash = localStorage.getItem(CONTACT_INFO_STORAGE_KEY);
		} catch (err) {
			// Remove the localstorage item if it is malformed
			localStorage.removeItem(CONTACT_INFO_STORAGE_KEY);
		}
		if (!oldUserContactInfoHash) {
			commit('setContactInformationChangesPending', false);

			return;
		}

		const currentUserContactInfoHash = hash(getContactInfoFromState(state));

		// if old info is still equal to the current, then the change has not been inserted by the Reneos employee
		const isChangePending = currentUserContactInfoHash === oldUserContactInfoHash;

		if (!isChangePending) {
			// An update has happened => we should delete the local storage item
			localStorage.removeItem(CONTACT_INFO_STORAGE_KEY);
		}

		commit('setContactInformationChangesPending', isChangePending);
	},
	updateAddressInformationChangesPending({ commit, state }) {
		let oldUserAddressInfoHash;
		try {
			oldUserAddressInfoHash = localStorage.getItem(ADDRESS_INFO_STORAGE_KEY);
		} catch (err) {
			// Remove the localstorage item if it is malformed
			localStorage.removeItem(ADDRESS_INFO_STORAGE_KEY);
		}
		if (!oldUserAddressInfoHash) {
			commit('setAddressInformationChangesPending', false);

			return;
		}

		const currentUserAddressInfoHash = hash(getAddressInfoFromState(state));

		// if old info is still equal to the current, then the change has not been inserted by the Reneos employee
		const isChangePending = currentUserAddressInfoHash === oldUserAddressInfoHash;

		if (!isChangePending) {
			// An update has happened => we should delete the local storage item
			localStorage.removeItem(ADDRESS_INFO_STORAGE_KEY);
		}

		commit('setAddressInformationChangesPending', isChangePending);
	},
	resetUser({ commit }) {
		commit('resetUser');
	},
	async getEnvironmentVariables({ commit }) {
		try {
			const response = await serverService.get('/envs');

			if (response) {
				commit('setEnvs', response);
			}
		} catch (err) {
			console.error('Fetching the environment variables from the server failed', err);
		}
	},
};

// Mutations
// ------------------------------------------------------------------------- /
const mutations = {
	setUser(state, user) {
		state.user = user;
	},
	setReadNotifications(state, notifications) {
		Vue.set(state, 'notifications.read', notifications);
	},
	setUnreadNotifications(state, notifications) {
		Vue.set(state, 'notifications.unread', notifications);
	},
	setImportantNotifications(state, notifications) {
		Vue.set(state, 'notifications.important', notifications);
	},
	setReadNotificationsCount(state, count) {
		Vue.set(state, 'notifications.readCount', count);
	},
	setUnreadNotificationsCount(state, count) {
		Vue.set(state, 'notifications.unreadCount', count);
	},
	setContactInformationChangesPending(state, isChangePending) {
		state.isContactInformationChangesPending = isChangePending;
	},
	setAddressInformationChangesPending(state, isChangePending) {
		state.isAddressInformationChangesPending = isChangePending;
	},
	resetUser(state) {
		resetState(state, initialState);
		state.user = null;
		unset(state, 'notifications.read');
		unset(state, 'notifications.unread');
		unset(state, 'notifications.important');
	},
	setEnvs(state, envs) {
		state.envs = envs;
	},
	setLoading(state, loading) {
		state.loading = loading;
	},
};

export default {
	state: { ...initialState },
	getters,
	actions,
	mutations,
};
